<template>
    <div :class="[theme.quickbalances.quickbalances, s.module]">
        <div :class="s.header" class="draggable">
            <div :class="s.group">
                <AccountColorMarker
                    :color="activeAccountColor"
                    :withName="{ name: activeAccountName, description: activeAccountDescription }"
                />
                <div :class="s.title">
                    OTC Balance
                </div>
                <NumberFormater
                    v-if="activeAccount"
                    :precision="balancePrecision"
                    :symbol="quotationAssetCharacter"
                    :value="activeAccount.totalUsdOtc"
                />
            </div>
            <div :class="s.group">
                <router-link
                    to="/wallets"
                    :class="s.underlined"
                >
                    See Wallets&rarr;
                </router-link>
                <Search
                    @on-search="search = $event"
                />
            </div>
        </div>
        <div :class="s.moduleContent">
            <div :class="s.w100">
                <div :class="s.subheaderContainer">
                    <div>
                        <div :class="s.checkboxesContainer">
                            <Checkbox
                                v-for="checkbox in assetTypes"
                                :key="checkbox.name"
                                :value="checkbox.value"
                                @state-changed="handleCheckbox(checkbox.name, checkbox.value)"
                                :label-class="theme.quickbalances.menuText"
                                :label-active-class="theme.quickbalances.active"
                            >
                                <template>
                                    {{ checkbox.name }}
                                </template>
                            </Checkbox>
                        </div>
                    </div>
                    <div v-if="hasAnyBalance">
                        <span
                            @click="filterEmptyBalances = !filterEmptyBalances"
                            :class="theme.quickbalances.zeroBalancesSwitch"
                        >
                            {{ filterEmptyBalances ? 'Show' : 'Hide' }} Zero Balances
                        </span>
                    </div>
                </div>
                <div :class="s.contentContainer">
                    <div :class="s.maxContent">
                        <div
                            ref="grid"
                            :class="[s.balancesGrid]"
                        >
                            <Balance
                                v-for="balance in activeBalances"
                                :key="balance.id"
                                ref="balance"
                                :balance="balance"
                                :quotableAssets="quotableAssets"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { assetsTypes } from 'Store/v2/Assets';
import { calculatePrecision } from 'Lib/utils/quotationAssetPrecisionCalculator';
import { getQuickBalancesPositions } from 'Store/v2/Futures';
import AccountColorMarker from 'Common/AccountColorMarker.vue';
import theme from 'Theme';
import NumberFormater from 'UI/NumberFormater.vue';
import { OTC_PLACEMENT } from 'Const/otc';
import Checkbox from 'Control/Checkbox.vue';
import Search from 'Control/Search.vue';

import Balance from './components/Balance.vue';

export default {
    name: 'AccountPortfolio',
    components: {
        Search,
        Checkbox,
        AccountColorMarker,
        Balance,
        NumberFormater,
    },
    props: {
        quotableAssets: Array,
    },
    data() {
        return {
            assetTypes: [
                { name: 'All', value: true },
                { name: 'Fiat', value: true },
                { name: 'Stablecoin', value: true },
                { name: 'Crypto', value: true },
            ],
            search: '',
            assetsTypes,
            filterEmptyBalances: false,
            currentTypeFilter: null,
            theme,
            togglePlacementListenerId: null,
            toggledFiats: false,
            toggledCrypto: false,
            toggledStablecoin: false,
        };
    },
    computed: {
        ...mapGetters({
            activeAccountName: 'Accounts/activeAccountName',
            activeAccountId: 'Accounts/activeAccountID',
            activeAccountDescription: 'Accounts/activeAccountDescription',
            quotationAssetCharacter: 'Assets/GET_QUOTATION_ASSET_CHARACTER',
            quotationAssetPrecision: 'Assets/GET_QUOTATION_ASSET_PRECISION',
            activeAccount: 'Accounts/activeAccount',
            isThemeDark: 'isThemeDark',
        }),
        hasAnyBalance() {
            return this.balances.filter(({ total }) => total > 0).length > 0;
        },
        accountPortfolioActivePlacementBalances() {
            return this.$store.getters['Balances/GET_ACTIVE_ACCOUNT_BALANCES_BY_PLACEMENT_NAME'](OTC_PLACEMENT);
        },
        activeAccountColor() {
            if (!this.activeAccount || !this.activeAccount.color) {
                return this.isThemeDark ? '#23232A' : '#f1f2f5';
            }
            return this.activeAccount.color;
        },
        isCurrentPlacementFutures() {
            return false;
        },
        currencyTypeToggleAdapter: {
            get() {
                return this.currenciesTypes.filter((t) => t.type !== 'fiat').findIndex(({ type }) => type === this.currentTypeFilter);
            },
            set({ type }) {
                this.currentTypeFilter = type;
            },
        },

        balances() {
            const result = [];
            const assetsArray = this.$store.state.OTC.assets;

            assetsArray.forEach(
                ({
                    symbol, type, multiplier, placementName, placementId, id, blockchainName,
                }) => {
                    const currentBalances = this.accountPortfolioActivePlacementBalances.filter(
                        ({ assetSymbol }) => assetSymbol === symbol,
                    );
                    if (currentBalances.length === 0) {
                        result.push({
                            id,
                            assetSymbol: symbol,
                            assetType: type,
                            assetPrecision: Math.log10(multiplier),
                            placementName,
                            placementId,
                            blockchainName,
                            total: 0,
                            free: 0,
                            hold: 0,
                        });
                    } else {
                        currentBalances.forEach((balance) => {
                            result.push(balance);
                        });
                    }
                },
            );
            return result;
        },
        activeBalances() {
            const activeTypes = this.assetTypes
                .filter(({ value }) => value)
                .flatMap(({ name }) => {
                    switch (name) {
                        case 'All': {
                            return [assetsTypes.FIAT, assetsTypes.CRYPTO_SPOT, assetsTypes.STABLE_COIN];
                        }
                        case 'Fiat': {
                            return [assetsTypes.FIAT];
                        }
                        case 'Stablecoin': {
                            return [assetsTypes.STABLE_COIN];
                        }
                        case 'Crypto': {
                            return [assetsTypes.CRYPTO_SPOT];
                        }
                        default: {
                            return '';
                        }
                    }
                });
            return this.balances
                .filter(({ assetSymbol, assetType, total }) => assetSymbol
                    && activeTypes.includes(assetType)
                    && (!this.filterEmptyBalances || total > 0)
                    && (this.search === '' ? true : assetSymbol.toLowerCase().indexOf(this.search.toLowerCase()) !== -1))
                .sort(({ total: a }, { total: b }) => b - a);
        },
        currenciesTypes() {
            return [
                {
                    name: 'All currencies',
                    type: null,
                },
                ...Object.values(assetsTypes).map((type) => ({
                    name: this.$store.getters.localization.Body.Terminal.AccountPortfolio.BalancesTypes[type].Name,
                    type,
                })),
            ];
        },

        balancePrecision() {
            const quotationAssetSymbol = this.$store.getters['Assets/GET_QUOTATION_ASSET_SYMBOL'];
            return calculatePrecision(quotationAssetSymbol);
        },
    },
    methods: {
        toggleAll(column) {
            let id;
            switch (column) {
                case 'Fiats': {
                    this.toggledFiats = false;
                    this.toggledCrypto = true;
                    this.toggledStablecoin = true;
                    id = 'Fiats';
                    break;
                }
                case 'Crypto': {
                    this.toggledFiats = true;
                    this.toggledCrypto = false;
                    this.toggledStablecoin = true;
                    id = 'Crypto';
                    break;
                }
                case 'Stablecoin': {
                    this.toggledFiats = true;
                    this.toggledCrypto = true;
                    this.toggledStablecoin = false;
                    id = 'Stablecoin';
                    break;
                }
            }
            document.querySelectorAll('.adaptive--tablet-landscape-up').forEach((div) => {
                div.classList.remove('solo');
            });
            document.getElementById(id).querySelector('.adaptive--tablet-landscape-up').classList.add('solo');
        },
        handleCheckbox(name, value) {
            if (name === 'All') {
                if (!value) {
                    this.assetTypes.map((el) => {
                        el.value = true;
                        return el;
                    });
                    return;
                }
                this.assetTypes.map((el) => {
                    if (el.name === name) {
                        el.value = !el.value;
                    }
                    return el;
                });
                return;
            }
            this.assetTypes.map((el) => {
                if (el.name === name) {
                    el.value = !el.value;
                }
                return el;
            });
            if (this.assetTypes.some(({ name: n, value: v }) => n !== 'All' && !v)) {
                this.assetTypes.map((el) => {
                    if (el.name === 'All') {
                        el.value = false;
                    }
                    return el;
                });
            } else {
                this.assetTypes.map((el) => {
                    if (el.name === 'All') {
                        el.value = true;
                    }
                    return el;
                });
            }
        },
    },
    async mounted() {
        if (this.activeAccountId) {
            await this.$store.dispatch(getQuickBalancesPositions(undefined));
        }
    },
    watch: {
        async activeAccountId(val, oldVal) {
            if (!oldVal) {
                await this.$store.dispatch(getQuickBalancesPositions(undefined));
            }
        },
    },
};
</script>

<style lang="postcss" module="s">
.title {
    margin: 0 var(--m-m);
}
.module {
    background: var(--cl-white);
    border-radius: 8px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    box-shadow: 0 0 10px var(--cl-expand);
    & .w100 {
        width: 100%;
    }
    & .maxContent {
        max-width: 100%;
    }
    & .subheaderContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 12px 10px;
        & .checkboxesContainer {
            display: flex;
            align-items: center;
            column-gap: 12px;
        }
    }
    & .contentContainer {
        display: flex;
        width: 100%;
        height: calc(100% - 40px);
        padding-top: 15px;
    }
    & .moduleContent {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-grow: 1;
        max-height: calc(100% - 35px);
        & .balancesGrid {
            display: grid;
            grid-auto-flow: column;
            grid-template-rows: 1fr 1fr 1fr 1fr;
            grid-column-gap: var(--m-xxl);
            grid-row-gap: var(--m-s);
            padding: 0 17px;
            overflow-x: scroll;
            min-height: 100%;
            &.noScrollBar {
                scrollbar-width: none;
            }
        }
    }
    & .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 35px;
        border-bottom: 1px solid var(--main-border-color);
        padding: 3px 12px 4px 0;
        z-index: 2;
        & .group {
            display: flex;
            align-items: center;
            column-gap: var(--m-m);
        }
        & .underlined {
            text-decoration: underline;
        }
    }
}
</style>
