// This file was autogenerated. Please do not change.
export interface IEmptyResult {
}

export default class EmptyResult {
    constructor(props: IEmptyResult) {
    }

    serialize(): IEmptyResult {
        const data: IEmptyResult = {
        };
        return data;
    }

    toJSON(): IEmptyResult {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
