// This file was autogenerated. Please do not change.
export interface ISpotOrderRequest {
    id: string;
}

export default class SpotOrderRequest {
    readonly _id: string;

    /**
     * Example: 798d4e0f-3ed7-46f3-98ce-71c1644f3224
     */
    get id(): string {
        return this._id;
    }

    constructor(props: ISpotOrderRequest) {
        this._id = props.id.trim();
    }

    serialize(): ISpotOrderRequest {
        const data: ISpotOrderRequest = {
            id: this._id,
        };
        return data;
    }

    toJSON(): ISpotOrderRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            id: typeof this._id === 'string' && !!this._id.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
