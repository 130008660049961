// This file was autogenerated. Please do not change.
export interface IOrderRequestParams {
    baseQuantity?: string;
    clientOrderId?: string;
    hasBaseQuantity?: boolean;
    hasClientOrderId?: boolean;
    hasPrice?: boolean;
    hasQuoteQuantity?: boolean;
    hasSlippage?: boolean;
    hasTimeInForce?: boolean;
    instrumentSymbol?: string;
    price?: string;
    quantityCase?: ('none' | 'baseQuantity' | 'quoteQuantity');
    quoteQuantity?: string;
    side?: ('buy' | 'sell');
    slippage?: string;
    timeInForce?: ('fok' | 'gtc' | 'ioc');
}

export default class OrderRequestParams {
    readonly _baseQuantity: string | undefined;

    get baseQuantity(): string | undefined {
        return this._baseQuantity;
    }

    readonly _clientOrderId: string | undefined;

    get clientOrderId(): string | undefined {
        return this._clientOrderId;
    }

    readonly _hasBaseQuantity: boolean | undefined;

    get hasBaseQuantity(): boolean | undefined {
        return this._hasBaseQuantity;
    }

    readonly _hasClientOrderId: boolean | undefined;

    get hasClientOrderId(): boolean | undefined {
        return this._hasClientOrderId;
    }

    readonly _hasPrice: boolean | undefined;

    get hasPrice(): boolean | undefined {
        return this._hasPrice;
    }

    readonly _hasQuoteQuantity: boolean | undefined;

    get hasQuoteQuantity(): boolean | undefined {
        return this._hasQuoteQuantity;
    }

    readonly _hasSlippage: boolean | undefined;

    get hasSlippage(): boolean | undefined {
        return this._hasSlippage;
    }

    readonly _hasTimeInForce: boolean | undefined;

    get hasTimeInForce(): boolean | undefined {
        return this._hasTimeInForce;
    }

    readonly _instrumentSymbol: string | undefined;

    get instrumentSymbol(): string | undefined {
        return this._instrumentSymbol;
    }

    readonly _price: string | undefined;

    get price(): string | undefined {
        return this._price;
    }

    readonly _quantityCase: ('none' | 'baseQuantity' | 'quoteQuantity') | undefined;

    get quantityCase(): ('none' | 'baseQuantity' | 'quoteQuantity') | undefined {
        return this._quantityCase;
    }

    readonly _quoteQuantity: string | undefined;

    get quoteQuantity(): string | undefined {
        return this._quoteQuantity;
    }

    readonly _side: ('buy' | 'sell') | undefined;

    get side(): ('buy' | 'sell') | undefined {
        return this._side;
    }

    readonly _slippage: string | undefined;

    get slippage(): string | undefined {
        return this._slippage;
    }

    readonly _timeInForce: ('fok' | 'gtc' | 'ioc') | undefined;

    get timeInForce(): ('fok' | 'gtc' | 'ioc') | undefined {
        return this._timeInForce;
    }

    constructor(props: IOrderRequestParams) {
        if (typeof props.baseQuantity === 'string') {
            this._baseQuantity = props.baseQuantity.trim();
        }
        if (typeof props.clientOrderId === 'string') {
            this._clientOrderId = props.clientOrderId.trim();
        }
        if (typeof props.hasBaseQuantity === 'boolean') {
            this._hasBaseQuantity = props.hasBaseQuantity;
        }
        if (typeof props.hasClientOrderId === 'boolean') {
            this._hasClientOrderId = props.hasClientOrderId;
        }
        if (typeof props.hasPrice === 'boolean') {
            this._hasPrice = props.hasPrice;
        }
        if (typeof props.hasQuoteQuantity === 'boolean') {
            this._hasQuoteQuantity = props.hasQuoteQuantity;
        }
        if (typeof props.hasSlippage === 'boolean') {
            this._hasSlippage = props.hasSlippage;
        }
        if (typeof props.hasTimeInForce === 'boolean') {
            this._hasTimeInForce = props.hasTimeInForce;
        }
        if (typeof props.instrumentSymbol === 'string') {
            this._instrumentSymbol = props.instrumentSymbol.trim();
        }
        if (typeof props.price === 'string') {
            this._price = props.price.trim();
        }
        if (props.quantityCase) {
            this._quantityCase = props.quantityCase;
        }
        if (typeof props.quoteQuantity === 'string') {
            this._quoteQuantity = props.quoteQuantity.trim();
        }
        if (props.side) {
            this._side = props.side;
        }
        if (typeof props.slippage === 'string') {
            this._slippage = props.slippage.trim();
        }
        if (props.timeInForce) {
            this._timeInForce = props.timeInForce;
        }
    }

    serialize(): IOrderRequestParams {
        const data: IOrderRequestParams = {
        };
        if (typeof this._baseQuantity !== 'undefined') {
            data.baseQuantity = this._baseQuantity;
        }
        if (typeof this._clientOrderId !== 'undefined') {
            data.clientOrderId = this._clientOrderId;
        }
        if (typeof this._hasBaseQuantity !== 'undefined') {
            data.hasBaseQuantity = this._hasBaseQuantity;
        }
        if (typeof this._hasClientOrderId !== 'undefined') {
            data.hasClientOrderId = this._hasClientOrderId;
        }
        if (typeof this._hasPrice !== 'undefined') {
            data.hasPrice = this._hasPrice;
        }
        if (typeof this._hasQuoteQuantity !== 'undefined') {
            data.hasQuoteQuantity = this._hasQuoteQuantity;
        }
        if (typeof this._hasSlippage !== 'undefined') {
            data.hasSlippage = this._hasSlippage;
        }
        if (typeof this._hasTimeInForce !== 'undefined') {
            data.hasTimeInForce = this._hasTimeInForce;
        }
        if (typeof this._instrumentSymbol !== 'undefined') {
            data.instrumentSymbol = this._instrumentSymbol;
        }
        if (typeof this._price !== 'undefined') {
            data.price = this._price;
        }
        if (typeof this._quantityCase !== 'undefined') {
            data.quantityCase = this._quantityCase;
        }
        if (typeof this._quoteQuantity !== 'undefined') {
            data.quoteQuantity = this._quoteQuantity;
        }
        if (typeof this._side !== 'undefined') {
            data.side = this._side;
        }
        if (typeof this._slippage !== 'undefined') {
            data.slippage = this._slippage;
        }
        if (typeof this._timeInForce !== 'undefined') {
            data.timeInForce = this._timeInForce;
        }
        return data;
    }

    toJSON(): IOrderRequestParams {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            clientOrderId: !this._clientOrderId ? true : typeof this._clientOrderId === 'string',
            hasClientOrderId: !this._hasClientOrderId ? true : typeof this._hasClientOrderId === 'boolean',
            instrumentSymbol: !this._instrumentSymbol ? true : typeof this._instrumentSymbol === 'string',
            price: !this._price ? true : typeof this._price === 'string',
            hasPrice: !this._hasPrice ? true : typeof this._hasPrice === 'boolean',
            baseQuantity: !this._baseQuantity ? true : typeof this._baseQuantity === 'string',
            hasBaseQuantity: !this._hasBaseQuantity ? true : typeof this._hasBaseQuantity === 'boolean',
            quoteQuantity: !this._quoteQuantity ? true : typeof this._quoteQuantity === 'string',
            hasQuoteQuantity: !this._hasQuoteQuantity ? true : typeof this._hasQuoteQuantity === 'boolean',
            slippage: !this._slippage ? true : typeof this._slippage === 'string',
            hasSlippage: !this._hasSlippage ? true : typeof this._hasSlippage === 'boolean',
            hasTimeInForce: !this._hasTimeInForce ? true : typeof this._hasTimeInForce === 'boolean',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
