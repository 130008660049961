import ApiError from 'Entities/ApiError';
import CancelOrderRequest from 'Entities/omnibusSpotOrderRegistrator/CancelOrderRequest';
import EmptyResult from 'Entities/omnibusSpotOrderRegistrator/EmptyResult';
import ErrorResponse from 'Entities/omnibusSpotOrderRegistrator/ErrorResponse';
import PlaceOrderRequest from 'Entities/omnibusSpotOrderRegistrator/PlaceOrderRequest';
import PlaceOrderResponseWrapper from 'Entities/omnibusSpotOrderRegistrator/PlaceOrderResponseWrapper';
import ServiceStatus from 'Entities/omnibusSpotOrderRegistrator/ServiceStatus';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class OTCApi {
    static async cancel(cancelorderrequest: CancelOrderRequest, withHeaders?: boolean): Promise<{ data: EmptyResult; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...cancelorderrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('cancel', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/otc/order/cancel`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(cancelorderrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new EmptyResult(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('OTC/cancel', { response: res, data: result, fields: [JSON.stringify({ cancelorderrequest })] });
    }

    static async place(placeorderrequest: PlaceOrderRequest, withHeaders?: boolean): Promise<{ data: PlaceOrderResponseWrapper; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...placeorderrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('place', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/otc/order/place`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(placeorderrequest.serialize()),
        });
        if (res.status === 201) {
            return { data: new PlaceOrderResponseWrapper(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('OTC/place', { response: res, data: result, fields: [JSON.stringify({ placeorderrequest })] });
    }
}
