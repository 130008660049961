
import Vue from 'vue';

import theme from 'Theme';
import { assetsTypes } from 'Store/v2/Assets';
import Asset from 'Lib/entities/publicPresenter/Asset';
import { SET_ACTIVE_ASSET } from 'Store/v2/OTC';

interface Props {
    balance: any;
    quotableAssets: Asset[];
}

interface Data {
    theme: any;
    assetsTypes: any;
}

interface Computed {
    precision: number;
    free: number;
    summaryHold: number;
    isQuotable: boolean;
}

interface Methods {
    onAsset(): void
}

export default Vue.extend<Data, Methods, Computed, Props>({
    props: {
        balance: {
            type: undefined,
            required: true,
        },
        quotableAssets: Array,
    },
    data() {
        return {
            theme,
            assetsTypes,
            showModal: false,
        };
    },
    computed: {
        precision() {
            if (this.balance.assetType === this.assetsTypes.FIAT) {
                return 2;
            }
            if (this.balance.assetType === this.assetsTypes.CRYPTO_SPOT) {
                return 8;
            }
            if (this.balance.assetType === this.assetsTypes.STABLE_COIN) {
                return 4;
            }
            return 8;
        },
        isQuotable() {
            return !!this.quotableAssets.find((a) => a.symbol === this.balance.assetSymbol);
        },
        summaryHold() {
            return this.balance.hold + (this.balance.positionsHold ?? 0);
        },
        free() {
            if (this.balance.free < 0) {
                return 0;
            }
            return this.balance.free;
        },
    },
    methods: {
        onAsset() {
            if (this.isQuotable) {
                this.$store.commit(SET_ACTIVE_ASSET(this.balance.assetSymbol));
            }
        },
    },
});
