
import Vue from 'vue';
import { mapGetters } from 'vuex';

import AccountColorMarker from 'Common/AccountColorMarker.vue';
import Button from 'Control/Button.vue';
import Account from 'Entities/privatePresenter/Account';
import TradingApi from 'Apis/Trading';
import PlaceOrderRequest from 'Entities/orderRegistrator/PlaceOrderRequest';
import { OTC_PLACEMENT } from 'Const/otc';
import AssetValue from 'UI/AssetValue.vue';
import { stringToFixedDigits } from 'Lib/utils/stringToFixedDigits';
import { calculatePrecision } from 'Lib/utils/quotationAssetPrecisionCalculator';
import PublicDataApi from 'Apis/PublicData';
import AssetQuotationRequest from 'Lib/entities/publicPresenter/AssetQuotationRequest';

interface Props {
    id: string;
    confirmData: {
        mode: string;
        quantityToFixed: number;
        priceToFixed: number;
        baseAsset: string;
        quoteAsset: string;
        symbol: string;
        currency: string;
        value: string;
        price: number;
        quantityPrecision: number;
    };
}

interface Data {
    stringToFixedDigits: typeof stringToFixedDigits;
    orderTotal: string | number;
}

interface Methods {
    back: () => void;
    confirm: () => void;
    getOrderTotal: () => Promise<void>
}

interface Computed {
    activeAccount: Account;
    isThemeDark: boolean;
    activeAccountColor: string;
    quotationSymbol: string,
    orderValuePrecision: number,
}

export default Vue.extend<Data, Methods, Computed, Props>({
    components: {
        AccountColorMarker,
        Button,
        AssetValue,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        confirmData: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            stringToFixedDigits,
            orderTotal: 0,
        };
    },
    watch: {
        // eslint-disable-next-line func-names
        'confirmData.value': function () {
            this.getOrderTotal();
        },
        // eslint-disable-next-line func-names
        'confirmData.mode': function () {
            this.getOrderTotal();
        },
    },
    computed: {
        ...mapGetters({
            activeAccount: 'Accounts/activeAccount',
            isThemeDark: 'isThemeDark',
            quotationSymbol: 'Assets/GET_QUOTATION_ASSET_SYMBOL',
        }),
        activeAccountColor() {
            if (!this.activeAccount || !this.activeAccount.color) {
                return this.isThemeDark ? '#23232A' : '#f1f2f5';
            }
            return this.activeAccount.color;
        },
        orderValuePrecision() {
            return calculatePrecision(this.quotationSymbol);
        },
    },
    methods: {
        async getOrderTotal() {
            const { data } = await PublicDataApi.publicGetAssetQuotation(new AssetQuotationRequest({
                quotationSymbol: this.quotationSymbol,
                symbol: this.confirmData.mode === 'sell' ? this.confirmData.baseAsset : this.confirmData.quoteAsset,
            }));
            this.orderTotal = data.rate * Number(this.confirmData.value);
        },
        back() {
            this.$modal.hide(this.id);
        },
        async confirm() {
            await TradingApi.privateOrdersPlaceOrder(new PlaceOrderRequest({
                accountId: this.activeAccount.id,
                assetPairSymbol: this.confirmData.symbol,
                marketPrice: Number(this.confirmData.price),
                placementName: OTC_PLACEMENT,
                quantity: Number(this.confirmData.value),
                side: this.confirmData.mode.toUpperCase() as 'BUY' | 'SELL',
                type: 'MARKET',
            }));
            this.$modal.hide(this.id);
        },
    },
});
