
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { init, SET_ACTIVE_ASSET } from 'Store/v2/OTC';
import PageLayout from 'Common/PageLayout.vue';
import { gridPages } from 'Models/interface';
import Asset from 'Entities/publicPresenter/Asset';
import SpotAssetPair from 'Entities/publicPresenter/SpotAssetPair';
import OrdersHistory from 'Modules/OrdersHistory/OrdersHistory.vue';
import Icon from 'UI/Icon.vue';
import { getFavoriteAssetPairsList } from 'Store/v2/AssetPairs';
import { OTC_PLACEMENT } from 'Const/otc';
import Search from 'Control/Search.vue';
import NoData from 'UI/NoData.vue';

import AccountPortfolio from './components/AccountPortfolio/AccountPortfolio.vue';
import OrderCard from './components/OrderCard/OrderCard.vue';

type Data = {
    currentBreakpoint: string;
    isSearchActive: boolean;
    searchValue: string;
}

type Methods = {
    breakpointChangedEvent: (br: string) => void;
    getLayoutElementByName: (l: any, name: string) => any;
    setActiveAsset: (symbol: string | undefined) => void;
    onSearch: (data: string) => void;
};

type Computed = {
    assets: Asset[];
    filteredAssets: Asset[];
    activeAsset: Asset | undefined;
    quotableAssets: Asset[];
    assetPairs: SpotAssetPair[];
    filteredAssetPairs: SpotAssetPair[];
    orderCardsHeight: number;
    layout: any;
    vueGridLayoutBreakpoints: any;
    vueGridLayoutCols: any;
};

type Props = {
};

export default Vue.extend<Data, Methods, Computed, Props>({
    name: 'OTC',
    components: {
        NoData,
        Search,
        OrdersHistory,
        PageLayout,
        AccountPortfolio,
        OrderCard,
        Icon,
    },
    data() {
        return {
            currentBreakpoint: 'lg',
            isSearchActive: false,
            searchValue: '',
        };
    },
    computed: {
        ...mapGetters({
            vueGridLayoutBreakpoints: 'vueGridLayoutBreakpoints',
            vueGridLayoutCols: 'vueGridLayoutCols',
        }),
        filteredAssets() {
            return this.quotableAssets
                .filter(({ symbol }) => symbol.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1);
        },
        assets() {
            return this.$store.state.OTC.assets;
        },
        activeAsset() {
            return this.$store.state.OTC.activeAsset;
        },
        quotableAssets() {
            const quotable = new Set<string>();
            const allPairs = this.$store.state.OTC.assetPairs;
            allPairs.forEach((as) => {
                quotable.add(as.quoteAssetSymbol!);
            });
            return this.assets.filter((as) => quotable.has(as.symbol));
        },
        assetPairs() {
            const allPairs = this.$store.state.OTC.assetPairs;
            if (this.activeAsset) {
                return allPairs.filter((p) => p.quoteAssetSymbol === this.activeAsset?.symbol);
            }
            if (this.$store.state.AssetPairs.favoriteTools) {
                const fav = (this.$store.state.AssetPairs.favoriteTools as Asset[]).reduce<Set<string>>((
                    prev, { placementName, symbol },
                ) => {
                    if (placementName === OTC_PLACEMENT) {
                        prev.add(symbol);
                    }
                    return prev;
                }, new Set<string>());

                return allPairs.filter((p) => fav.has(p.symbol));
            }
            return [] as SpotAssetPair[];
        },
        filteredAssetPairs() {
            if (this.searchValue === '') {
                return this.assetPairs;
            }

            return this.$store.state.OTC.assetPairs
                .filter(({ symbol }) => symbol.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1);
        },
        orderCardsHeight() {
            const minHeight = this.getLayoutElementByName(this.layout, 'OrderCards').h;
            const rowsNum = Math.ceil((this.assetPairs.length / 4));
            return minHeight * rowsNum;
        },
        layout: {
            get() {
                return this.$store.getters.pageGrid(gridPages.OTC, this.currentBreakpoint);
            },
            set(grid) {
                this.$store.dispatch('changePageGrid', {
                    page: gridPages.OTC,
                    grid,
                    breakpoint: this.currentBreakpoint,
                });
            },
        },
    },
    methods: {
        onSearch(val) {
            this.searchValue = val;
        },
        breakpointChangedEvent(breakpoint) {
            this.currentBreakpoint = breakpoint;
        },
        getLayoutElementByName(layout: any, name: string) {
            const element = layout.find((el) => el.name === name);
            return element || {};
        },
        setActiveAsset(symbol: string | undefined) {
            this.$store.commit(SET_ACTIVE_ASSET(symbol));
        },
    },
    props: {},
    mounted() {
        this.$store.dispatch(init(undefined));
        this.$store.dispatch(getFavoriteAssetPairsList(undefined));
    },
});

