
import Vue from 'vue';

interface Props {
    value: number;
    precision: number;
    prefix?: string;
    symbol?: string;
    numberClassName?: string;
    fractionClassName?: string;
}
interface Computed {
    int: string;
    fraction: string;
}

type Empty = Record<string, never>;

export default Vue.extend<Empty, Empty, Computed, Props>({
    props: {
        symbol: {
            type: String,
            default: '',
        },
        value: {
            type: Number,
            default: 0,
        },
        precision: {
            type: Number,
            default: 0,
        },
        prefix: {
            type: String,
            default: '',
        },
        numberClassName: {
            type: String,
            default: '',
        },
        fractionClassName: {
            type: String,
            default: '',
        },
    },
    computed: {
        int() {
            return this.value.getInt().getSeparatedDigits();
        },
        fraction() {
            return this.value.toFixed(this.precision).fraction();
        },
    },
});
