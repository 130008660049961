import OrderRequestParams, { IOrderRequestParams } from './OrderRequestParams';

// This file was autogenerated. Please do not change.
export interface IPlaceOrderRequest {
    accountId: string;
    hasRfqId?: boolean;
    orderParamsCase?: ('none' | 'orderRequestParams' | 'rfqId');
    orderRequestParams?: IOrderRequestParams;
    placementName: string;
    rfqId?: string;
    type: ('limit' | 'limitStop' | 'market' | 'marketStop' | 'requestForQuote');
}

export default class PlaceOrderRequest {
    readonly _accountId: string;

    get accountId(): string {
        return this._accountId;
    }

    readonly _hasRfqId: boolean | undefined;

    get hasRfqId(): boolean | undefined {
        return this._hasRfqId;
    }

    readonly _orderParamsCase: ('none' | 'orderRequestParams' | 'rfqId') | undefined;

    get orderParamsCase(): ('none' | 'orderRequestParams' | 'rfqId') | undefined {
        return this._orderParamsCase;
    }

    readonly _orderRequestParams: OrderRequestParams | undefined;

    get orderRequestParams(): OrderRequestParams | undefined {
        return this._orderRequestParams;
    }

    readonly _placementName: string;

    get placementName(): string {
        return this._placementName;
    }

    readonly _rfqId: string | undefined;

    get rfqId(): string | undefined {
        return this._rfqId;
    }

    readonly _type: ('limit' | 'limitStop' | 'market' | 'marketStop' | 'requestForQuote');

    get type(): ('limit' | 'limitStop' | 'market' | 'marketStop' | 'requestForQuote') {
        return this._type;
    }

    constructor(props: IPlaceOrderRequest) {
        this._accountId = props.accountId.trim();
        if (typeof props.hasRfqId === 'boolean') {
            this._hasRfqId = props.hasRfqId;
        }
        if (props.orderParamsCase) {
            this._orderParamsCase = props.orderParamsCase;
        }
        if (props.orderRequestParams) {
            this._orderRequestParams = new OrderRequestParams(props.orderRequestParams);
        }
        this._placementName = props.placementName.trim();
        if (typeof props.rfqId === 'string') {
            this._rfqId = props.rfqId.trim();
        }
        this._type = props.type;
    }

    serialize(): IPlaceOrderRequest {
        const data: IPlaceOrderRequest = {
            accountId: this._accountId,
            placementName: this._placementName,
            type: this._type,
        };
        if (typeof this._hasRfqId !== 'undefined') {
            data.hasRfqId = this._hasRfqId;
        }
        if (typeof this._orderParamsCase !== 'undefined') {
            data.orderParamsCase = this._orderParamsCase;
        }
        if (typeof this._orderRequestParams !== 'undefined') {
            data.orderRequestParams = this._orderRequestParams.serialize();
        }
        if (typeof this._rfqId !== 'undefined') {
            data.rfqId = this._rfqId;
        }
        return data;
    }

    toJSON(): IPlaceOrderRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: typeof this._accountId === 'string' && !!this._accountId.trim(),
            orderRequestParams: !this._orderRequestParams ? true : this._orderRequestParams.validate().length === 0,
            rfqId: !this._rfqId ? true : typeof this._rfqId === 'string',
            hasRfqId: !this._hasRfqId ? true : typeof this._hasRfqId === 'boolean',
            placementName: typeof this._placementName === 'string' && !!this._placementName.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
