// This file was autogenerated. Please do not change.
export interface IPlaceOrderResponseWrapper {
    orderId?: string;
    price?: string;
    status?: string;
}

export default class PlaceOrderResponseWrapper {
    readonly _orderId: string | undefined;

    get orderId(): string | undefined {
        return this._orderId;
    }

    readonly _price: string | undefined;

    get price(): string | undefined {
        return this._price;
    }

    readonly _status: string | undefined;

    get status(): string | undefined {
        return this._status;
    }

    constructor(props: IPlaceOrderResponseWrapper) {
        if (typeof props.orderId === 'string') {
            this._orderId = props.orderId.trim();
        }
        if (typeof props.price === 'string') {
            this._price = props.price.trim();
        }
        if (typeof props.status === 'string') {
            this._status = props.status.trim();
        }
    }

    serialize(): IPlaceOrderResponseWrapper {
        const data: IPlaceOrderResponseWrapper = {
        };
        if (typeof this._orderId !== 'undefined') {
            data.orderId = this._orderId;
        }
        if (typeof this._price !== 'undefined') {
            data.price = this._price;
        }
        if (typeof this._status !== 'undefined') {
            data.status = this._status;
        }
        return data;
    }

    toJSON(): IPlaceOrderResponseWrapper {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            price: !this._price ? true : typeof this._price === 'string',
            status: !this._status ? true : typeof this._status === 'string',
            orderId: !this._orderId ? true : typeof this._orderId === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
