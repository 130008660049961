// This file was autogenerated. Please do not change.
export interface IAssetQuotationRequest {
    quotationSymbol: string;
    symbol: string;
}

export default class AssetQuotationRequest {
    readonly _quotationSymbol: string;

    /**
     * Description: Asset symbol to be quoted to
     * Example: USDT
     */
    get quotationSymbol(): string {
        return this._quotationSymbol;
    }

    readonly _symbol: string;

    /**
     * Description: Asset symbol to be quoted
     * Example: BTC
     */
    get symbol(): string {
        return this._symbol;
    }

    constructor(props: IAssetQuotationRequest) {
        this._quotationSymbol = props.quotationSymbol.trim();
        this._symbol = props.symbol.trim();
    }

    serialize(): IAssetQuotationRequest {
        const data: IAssetQuotationRequest = {
            quotationSymbol: this._quotationSymbol,
            symbol: this._symbol,
        };
        return data;
    }

    toJSON(): IAssetQuotationRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            quotationSymbol: typeof this._quotationSymbol === 'string' && !!this._quotationSymbol.trim(),
            symbol: typeof this._symbol === 'string' && !!this._symbol.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
